<template>
  <div class="page">
    <!-- 正面是文字，反面是图片 -->
    <div class="card card-left">
      <div class="font">
        <div class="pic">
          <img src="../assets/images/wt45.jpg" />
        </div>

                <div class="video-wrapper">
          <video id="myVideo"></video>
          <div v-show="show">
          <button @click="playNextVideo">Next</button>
                    <button @click="playBackVideo">Back</button>
          </div>
        </div>

        <div class="gest">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <!-- <div class="pic"> -->
          <!-- <img src="../assets/images/wt45.jpg" /> -->
        <!-- </div> -->
        <!-- <div class="video-wrapper"> -->
          <!-- <video id="myVideo"></video> -->
          <!-- <button @click="playNextVideo">Next</button> -->
        <!-- </div> -->
        <!-- <transition name="bounce-in">
        <div v-if="show" class="Demo">
          <video src="https://home/Res/Test0001.mp4"  controls="controls"> </video>
        </div>
      </transition> -->
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>

      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt46.jpg" />
        </div>
<!-- <div class="video-wrapper"> -->
          <!-- <video id="Video1"></video> -->
          <!-- <button @click="playNextVideo">Next</button> -->
          
        <!-- </div> -->
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt47.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0006.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt49.png" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0007.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt51.png" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0008.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt52.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0009.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt53.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0010.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt54.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0011.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt55.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0012.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="tria1"></div>
      <div class="tria2"></div>
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
        <div class="pic">
          <img src="../assets/images/wt39.jpg" />
        </div>
        <transition name="bounce-in">
          <div v-if="show" class="Demo">
            <video
              src="https://home/Res/Test0013.mp4"
              controls="controls"
            ></video>
          </div>
        </transition>
        <div class="gest1">
          <img src="../assets/images/wt28.png" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="back">
        <div class="gest">
          <img src="../assets/images/wt27.png" />
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="header">
      <h1 id="text">WebMessageTest</h1>
  </div> -->
</template>


<script>
// import 'video.js/dist/video-js.css'
// import { videoPlayer } from 'vue-video-player'

export default {
  // name:"videoDetail",
  //     components:{
  //       videoPlayer
  //     },
  props: {
    msg: String,
  },

  data() {
    return {
      currentIndex: 0,
      videoList: [
        "https://home/Res/Test0001.mp4",
        "https://home/Res/Test0005.mp4",
        "https://home/Res/Test0006.mp4",
        "https://home/Res/Test0007.mp4",
        "https://home/Res/Test0008.mp4",
        "https://home/Res/Test0009.mp4",
        "https://home/Res/Test0010.mp4",
        "https://home/Res/Test0011.mp4",
        "https://home/Res/Test0012.mp4",
        "https://home/Res/Test0013.mp4",
      ],
      // dialogVisible:false,

      // playerOptions:{
      //   playbackRates:[0.5,1.0,1.5,2.0,3.0],
      //   autoplay:false,
      //   muted:false,
      //   loop:false,
      //   preload:'auto',
      //   language:'zh-CN',
      //   aspectRatio:'16:9',
      //   fluid:true,
      //   sources:[{
      //     type:"video/mp4",
      //     src:"https://home/Res/Test0008.mp4",
      //     type:"video/mp4",
      //     src:"https://home/Res/Test0002.mp4"
      //   }],
      //   poster:"../assets/images/wt45.jpg",
      //   notSupportedMessage:'此视频暂无法播放，请稍后再试',
      //   controlBar:{
      //     timeDivider:true,
      //     durationDisplay:true,
      //     remainingTimeDisplat:true,
      //     fullscreenToggle:true
      //   }
      // },
      show: false,
      pageNum: 0,
    };
  },

  // created(){
  //   const videoId = this.$route.params.videoId
  //   this.request(`/video/detail/${videoID}`).then(res =>{
  //     console.log(res.data)
  //     this.playerOptions.sources[0].src= "https://home/Res/Test0008.mp4"
  //      this.playerOptions.sources[1].src= "https://home/Res/Test0002.mp4"
  //   })
  // },

  mounted() {
    // setTimeout(() =>{
    //   this.show = true;
    // },2000)
    this.init()
    this.getPageInfo();
    // this.keyDown()
    console.log("window", window);
    // console.log(11111111111111);
    // setTimeout(() => {
    //   console.log(22222222222);
    // window.postMessage("SetGestureEventHandler=OnGestureRecognizer");
    // this.OnGestureRecognizer()
    // window.top.chrome.webview.postMessage("11111111111")
    // console.log('window.chrome',window.chrome);
    // console.log('window.chrome.webview',window.chrome.webview);
    // }, 1000);
    window.OnGestureRecognizer = this.OnGestureRecognizer;

    window.chrome.webview.postMessage(
      "SetGestureEventHandler=window.OnGestureRecognizer"
    );
  },

  methods: {
    init() {
      var video = document.getElementById("myVideo");
      video.addEventListener("ended", function () {
        this.currentIndex++;
        if (this.currentIndex >= this.videoList.length) {
          this.currentIndex = 0;
        }
        video.src = this.videoList[this.currentIndex];
        videoList[0].play();
      });
      video.addEventListener("started", function () {
        this.currentIndex--;
        if (this.currentIndex <= 0) {
          this.currentIndex = 9;
        }
        video.src = this.videoList[this.currentIndex];
        videoList[9].play();
      });

      video.src = this.videoList[this.currentIndex];
      video.play();
    },
    playNextVideo() {
      var video = document.getElementById("myVideo");
      
      this.currentIndex++;
      if (this.currentIndex >= this.videoList.length) {
        this.currentIndex = 0;
      }
      video.src = this.videoList[this.currentIndex];
      video.play();
      console.log(this.currentIndex)
      // currentIndex++;
      // if (currentIndex >= videoList.length) {
      //   currentIndex = 0;
      // }
      // video.src = videoList[currentIndex];
      // video.play();
    },
    playBackVideo() {
      var video = document.getElementById("myVideo");
      this.currentIndex--;
      if (this.currentIndex <= 0) {
        this.currentIndex = 9;
      }
      video.src = this.videoList[this.currentIndex];
      video.play();
      console.log(this.currentIndex)
    },
    getPageInfo() {
      // 调整z-index的值，让页面顺序显示，默认第一页在上面
      var runPage = document.querySelectorAll(".card");
      console.log("runPage", runPage);
      for (var i = 0, len = runPage.length; i < len; i++) {
        runPage[i].style.zIndex = len - i;
      }

      // 模拟翻书
      // setTimeout(() => {
      //   runPage[0].classList.add('left')
      // }, 1000);

      // this.keyDown()

      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        if (e1 && e1.keyCode == 39) {
          // 按下左箭头 上一页

          if (this.pageNum > 0) {
            this.pageNum--;
            runPage[this.pageNum].classList.remove("left");
            runPage[this.pageNum].classList.add("right");
            setTimeout(() => {
              runPage[this.pageNum].classList.remove("right");
            }, 500);
          } else {
            console.log("已经是到第一页了");
          }

          console.log("this.pageNum", this.pageNum);
        } else if (e1 && e1.keyCode == 37) {
          // 按下右箭头 下一页

          if (this.pageNum < len) {
            runPage[this.pageNum].classList.add("left");
            this.pageNum++;
          } else {
            console.log("已经是到最后一页了");
          }

          console.log("this.pageNum", this.pageNum);
        }
      };
    },
    OnGestureRecognizer(s) {
      
      var runPage = document.querySelectorAll(".card");
      console.log("runPage", runPage);
      for (var i = 0, len = runPage.length; i < len; i++) {
        runPage[i].style.zIndex = len - i;
      }

      console.log("OnGestureRecognizer:" + s);
      this.message = s;
      if (s === "BROWSER_FORWARD") {
        // if (this.pageNum > 0) {
          // this.pageNum--;
          // runPage[this.pageNum].classList.remove("left");
          // runPage[this.pageNum].classList.add("right");
          // setTimeout(() => {
            // runPage[this.pageNum].classList.remove("right");
          // }, 500);
        // } else {
          // console.log("已经是到第一页了");
        // }
this.playBackVideo();
        // console.log("this.pageNum", this.pageNum);
      } else if (s === "BROWSER_BACK") {
        // if (this.pageNum < len) {
          // runPage[this.pageNum].classList.add("left");
          // this.pageNum++;
         
        // } else {
          // console.log("已经是到最后一页了");
        // }
this.playNextVideo();
        // console.log("this.pageNum", this.pageNum);
        
      }
      
    },
  },
};
</script>


<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* .card .tria1::after{
    content:"";
    position: absolute;
    right: 0px;
    top: 0px;
    background: linear-gradient(-135deg,transparent 50%,#FFF2E2 0);
    width: 100px;
    height: 100px;
    border-bottom-left-radius: 4px;
    box-shadow: -0.2em 0.2em 0.2em #1a2b33;
}
.card .tria2::after{
    content:"";
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: linear-gradient(-45deg,transparent 50%,#FFF2E2 0);
    width: 100px;
    height: 100px;
    border-top-left-radius: 4px;
    box-shadow: -0.2em -0.2em 0.2em #1a2b33;
} 书页折角*/

/* .page {
    border: 1px solid #FAF9DE;
    position: relative;
    perspective: 4500px;
    background-color: #181818;
    ;
} */

.card {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;

  background-color: #444444;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
}

.left {
  /* width: 400px;
    height: 600px; */
  position: absolute;
  right: 0;
  /* background-color: #b5e8f1; */
  transform: rotateY(-180deg);
  z-index: 100 !important;
}

.right {
  z-index: 100 !important;
}

.card div.font,
.card div.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  color: #898484;
  font-size: 35px;
}

.card div.back {
  transform: rotateY(180deg);
  background: linear-gradient(90deg, #414141 0%, #3d3d3d 30%, #363636 100%);
  box-shadow: 30px 0px 150px 30px rgba(51, 51, 51, 0.3) inset;
  padding-left: 180px;
  padding-top: 50px;
  padding-right: 75px;
}

.card div.font {
  padding-right: 180px;
  padding-top: 50px;
  padding-left: 75px;
  box-shadow: 75px 0px 75px 0px rgba(51, 51, 51, 0.3) inset;
}

.card .font .pag {
  font-size: 25px;
  color: #796643;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.card .back .pag {
  font-size: 25px;
  color: #796643;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card .back .gest {
  width: 10%;
  height: 10%;
  position: absolute;
  top: 45%;
  left: 0;
}
.card .back .gest1 {
  width: 10%;
  height: 10%;
  position: absolute;
  top: 45%;
  right: 0;
}
.card .font .gest {
  width: 10%;
  height: 10%;
  position: absolute;
  top: 45%;
  right: 0;
}
.card .back .pic {
  height: 70%;
  width: 80%;
  position: absolute;
  left: 10%;
  top: 10px;
}
.card .font .pic{
  height: 70%;
  width: 80%;
  position: absolute;
  left: 10%;
  top: 10px;
}
.bounce-in-enter-active {
  transition: all 0.3s ease;
  transition-delay: 2s;
}
.bounce-in-leave-active {
  transition: all 0.8s cubic=bezier(1, 0.5, 0.8, 1);
}
.bounce-in-enter,
.bounce-in-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
video {
  height: 100%;
  width: 80%;
  position: absolute;
  left: 10%;
  top: 10px;
}
</style>